import React from 'react'
import { Helmet } from 'react-helmet'

import { graphql } from 'gatsby'

import Layout from '../../components/Layout'

import SEO from '../../components/SEO'
import GetInTouch from '../../components/GetInTouch'
import {
  FAQSection,
  HowItWorksSection,
  RecentWorkSection
} from '../../components/Section'

import ButtonGroup from '../../components/ButtonGroup'
import HeroFullBackground from '../../components/HeroFullBackground'

const JewelryDesignService = ({ data }) => {
  const { hero, name, metaDescription, slug, typeform, metaTitle } = data.contentfulService

  const postNode = {
    title: metaTitle || `${name} - Service | NYC | PrintAWorld`,
    metaDescription: metaDescription,
  }

  return (
    <div>
      <Layout lightMenu>
        <Helmet>
          <title>{postNode.title}</title>
        </Helmet>
        <SEO
          postNode={postNode}
          pagePath={`services/${slug}`}
          customTitle
          pageSEO
        />
        <HeroFullBackground
          hero={hero}
          buttonGroup={
            <ButtonGroup typeform={typeform} />
          }
        />
        <RecentWorkSection gallery={data.contentfulService.gallery} />
        <HowItWorksSection steps={data.contentfulService.howItWorks} />
        <FAQSection faqs={data.contentfulService.frequentlyAskedQuestions} />
        <GetInTouch />
      </Layout>
    </div>
  )
}

export const manufacturingPageQuery = graphql`
         query {
           contentfulService(slug: { eq: "jewelry-design" }) {
             name
             slug
             typeform
             metaTitle
             metaDescription {
               internal {
                 content
               }
             }
             hero {
               ... on Node {
                 ... on ContentfulHeroTextOnly {
                   ...HeroTextOnly
                 }
                 ... on ContentfulHero {
                   ...HeroFragment
                 }
               }
             }
             howItWorks {
               ...HowItWorksCardFragment
             }
             gallery {
               images {
                 ...RecentWorkImages
               }
             }
             frequentlyAskedQuestions {
               question
               id
               answer {
                 childMarkdownRemark {
                   html
                 }
               }
             }
           }
         }
       `

export default JewelryDesignService
